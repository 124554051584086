import PropTypes from 'prop-types';

import { fontSizes } from '@config/constants';

import { StyledFontButton, StyledFontSwitcher } from './FontSwitcher.styled';

const FontSwitcher = ({
  letterSwitchText,
  selectedFontSize,
  onChangeFontSize
}) => (
  <StyledFontSwitcher>
    <StyledFontButton
      fontSize='sm'
      isSelected={selectedFontSize === fontSizes.SM}
      onClick={() => onChangeFontSize(fontSizes.SM)}
    >
      {letterSwitchText}
    </StyledFontButton>
    <StyledFontButton
      fontSize='md'
      isSelected={selectedFontSize === fontSizes.MD}
      onClick={() => onChangeFontSize(fontSizes.MD)}
    >
      {letterSwitchText}
    </StyledFontButton>
    <StyledFontButton
      fontSize='lg'
      isSelected={selectedFontSize === fontSizes.LG}
      onClick={() => onChangeFontSize(fontSizes.LG)}
    >
      {letterSwitchText}
    </StyledFontButton>
  </StyledFontSwitcher>
);

FontSwitcher.propTypes = {
  selectedFontSize: PropTypes.oneOf([fontSizes.SM, fontSizes.MD, fontSizes.LG])
    .isRequired,
  onChangeFontSize: PropTypes.func.isRequired,
  letterSwitchText: PropTypes.string.isRequired
};

export default FontSwitcher;
