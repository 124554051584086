import moment from 'moment';
import { useCallback } from 'react';

import useConfig from '@hooks/useConfig';

const useDate = (isUnix = false) => {
  const [{ language }] = useConfig();

  const dateFormatter = useCallback(
    (...args) => {
      if (isUnix) {
        return moment(...args).locale(language);
      }
      return moment(...args).locale(language);
    },
    [isUnix, language]
  );

  return dateFormatter;
};

export default useDate;
