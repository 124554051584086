import { onClickPerfil } from '@utils/session';
import PropTypes from 'prop-types';
import ModalPerfil from './ModalPerfil/ModalPerfil';
import {
  Container,
  ContainerTitle,
  ContainerIcon,
  Title,
  CustomStyledSelect,
  ArrowIcon
} from './UserBar.styled';
import arrowDown from '@assets/icons/down-arrow.svg';
import { upperCaseFirstLetter } from '@utils/strings';

export const UserBar = ({ userInfo, modalProfile }) => {
  return (
    <Container className='modalPerfiluser' onClick={() => onClickPerfil()}>
      <ContainerTitle>
        <Title bold>Hola, {upperCaseFirstLetter(userInfo?.name)}</Title>
      </ContainerTitle>
      <ContainerIcon>
        <CustomStyledSelect>
          <ArrowIcon svg={arrowDown} className='iconPerfil' />
        </CustomStyledSelect>
      </ContainerIcon>
      <div className='modalPerfil'>
        <ModalPerfil userLogged={userInfo} modalProfile={modalProfile} />
      </div>
    </Container>
  );
};
UserBar.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    token: PropTypes.string
  }),
  modalProfile: PropTypes.shape({
    myPersonAccount: PropTypes.string,
    companyAccount: PropTypes.string,
    goToAccount: PropTypes.string,
    logOut: PropTypes.string
  }).isRequired
};

UserBar.defaultProps = {
  userInfo: null
};

export default UserBar;
