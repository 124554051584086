/* eslint-disable no-use-before-define */
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import {
  Container,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

import {
  StyledList,
  StyledBrand,
  StyledSpacer,
  StyledListItem,
  StyledContainer,
  StyledCopyright,
  StyledImageSocialMediaContainer,
  StyledSocialMedia,
  StyledListContainer,
  StyledExternalBrands,
  StyledBrandsContainer,
  StyledSocialMediaContainer,
  StyledGrid
} from './Footer.styled';
import Link from '@components/Link/Link';
import Newsletter from '@components/Newsletter/Newsletter';
import { propTypes } from '@components/Novelty/noveltyPropTypes';

const Footer = ({ newsletter, footer, displayNewsletter }) => {
  const renderSocialMedia = (links) => (
    <StyledSocialMediaContainer>
      {links.map((link, index) => (
        <Link to={link?.url} key={link?.icon?.fileName + index}>
          <StyledImageSocialMediaContainer>
            <StyledSocialMedia
              className='social-network'
              src={link?.icon?.url}
              alt={link?.icon?.fileName}
            />
          </StyledImageSocialMediaContainer>
        </Link>
      ))}
    </StyledSocialMediaContainer>
  );

  const generateUniqueLinkIdentifier = (link) => {
    const uniqueIdentifier = link?.text?.trim().replace(/\s/g, '-');
    return `footer-${
      link.isExternalLink ? 'external' : 'internal'
    }-${uniqueIdentifier}`;
  };

  const renderLinks = (links) =>
    links.map((link, index) => (
      <StyledListItem key={link?.text + index}>
        <Link
          className='link'
          to={link.url}
          id={generateUniqueLinkIdentifier(link)}
        >
          {link.text}
        </Link>
      </StyledListItem>
    ));

  const renderBlock = (block, options = { isSocialMedia: false }) => (
    <StyledList>
      <StyledListItem type='title'>{block?.title || ''}</StyledListItem>
      {!options.isSocialMedia
        ? renderLinks(block?.links || block)
        : renderSocialMedia(block?.links || block)}
    </StyledList>
  );

  const classifyLogosAndRender = (logos) => {
    const internalLogos = [];
    const externalLogos = [];
    logos.forEach((logo) => {
      logo.typeLogo === 'internal'
        ? internalLogos.push(logo)
        : externalLogos.push(logo);
    });

    return renderLogoAndCertifications(internalLogos, externalLogos);
  };

  const renderLogoAndCertifications = (internalLogos, externalLogos) => (
    <>
      {buildLogoAndCertifications(internalLogos, true)}
      <StyledSpacer />
      {buildLogoAndCertifications(externalLogos, false)}
    </>
  );

  const generateUniqueLogoIdentifier = (fileName, isExternalLink) => {
    const uniqueIdentifier = fileName.trim().replace(/\.[^/.]+$/, '');
    return `footer-icon-brand-${
      isExternalLink !== null && isExternalLink ? 'external' : 'internal'
    }-${uniqueIdentifier}`;
  };

  const buildLogoAndCertifications = (arrayToBuild, isInternal) => (
    <StyledGrid numberColumns={arrayToBuild.length} isInternal={isInternal}>
      {arrayToBuild.map((logo, index) => (
        <StyledBrand
          className='brand'
          key={logo?.icon?.fileName + index}
          xs={3}
          md={1.3}
          isInternal={isInternal}
        >
          <Link
            to={logo.url}
            id={generateUniqueLogoIdentifier(
              logo?.icon?.fileName,
              logo.isExternalLink
            )}
          >
            <img src={logo.icon.url} alt={logo.icon.fileName} />
          </Link>
        </StyledBrand>
      ))}
    </StyledGrid>
  );

  return (
    <StyledContainer>
      <Container>
        {displayNewsletter && <Newsletter {...newsletter} />}
        <StyledListContainer displayNewsletter={displayNewsletter}>
          <Grid as='nav' fluid>
            <Row as='ol'>
              <Col as='li' xs={6} lg={2}>
                {renderBlock(footer.proceduresAndCertificates)}
              </Col>
              <Col as='li' xs={6} lg={2}>
                {renderBlock(footer.interesSites)}
              </Col>
              <Col as='li' xs={6} lg={2}>
                {renderBlock(footer.termsAndConditions)}
              </Col>
              <Col as='li' xs={6}>
                <Row as='ol'>
                  <Col as='li' xs={12} lg={4}>
                    {renderBlock(footer.othersLinks)}
                  </Col>
                  <Col
                    as='li'
                    xs={12}
                    md={6}
                    lg={4}
                    lgOffset={4}
                    style={{ zIndex: 4 }}
                  >
                    {renderBlock(footer.socialMedia, { isSocialMedia: true })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </StyledListContainer>
        <StyledBrandsContainer>
          <StyledExternalBrands>
            {classifyLogosAndRender(footer.logosAndCertifications.logos)}
          </StyledExternalBrands>
        </StyledBrandsContainer>
        <StyledCopyright>
          <Typography color='primary' textAlign='center' type='caption'>
            {footer.copyright}
          </Typography>
        </StyledCopyright>
      </Container>
    </StyledContainer>
  );
};

const linkProps = PropTypes.shape({
  url: PropTypes.string
});

const linksProps = PropTypes.arrayOf(linkProps);

const blockProps = PropTypes.shape({
  title: propTypes.string,
  links: linkProps
});

Footer.propTypes = {
  newsletter: PropTypes.shape({
    newsletterText: PropTypes.string.isRequired,
    termsAndConditionsText: PropTypes.string.isRequired
  }).isRequired,
  footer: PropTypes.shape({
    proceduresAndCertificates: blockProps,
    interesSites: blockProps,
    termsAndConditions: blockProps,
    othersLinks: PropTypes.arrayOf(linkProps),
    socialMedia: blockProps,
    logosAndCertifications: PropTypes.shape({
      logos: linksProps
    }),
    copyright: PropTypes.string
  }).isRequired,
  displayNewsletter: PropTypes.bool.isRequired
};

export default Footer;
