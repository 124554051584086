import styled from 'styled-components';
import { Col } from 'react-styled-flexboxgrid';
import {
  Modal,
  Button,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

export const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
`;

export const StyledButton = styled(Button)`
  height: 55px;
  border: 0px;
  :hover {
    background-color: ${({ theme }) => theme.colors.bg.login.normal};
    & span {
      color: ${({ theme }) => theme.colors.font.primary.normal};
    }
  }
`;

export const StyledLabel = styled(Typography)`
  margin: ${({ theme }) => theme.utils.spacing(2)};
`;
export const StyledCol = styled(Col)`
  padding: ${({ theme }) => theme.utils.spacing(2)};
`;
