import styled, { css } from 'styled-components';

import { Svg, Container } from '@bvcco/bvc-digital-market-component-library';

export const StyledLayout = styled.div`
  width: 100%;
`;

export const StyledContainer = styled.div`
  margin-top: 122px;
  ${({ theme }) => theme.breakpoints.md} {
    margin-top: 75px;
  }
`;

export const StyledBreadcrumbContainer = styled(Container)`
  max-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: ${({ theme }) => theme.utils.spacing(21)};
  margin-bottom: ${({ theme }) => theme.utils.spacing(2)};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.fixed};
  li:hover:not(:last-of-type) * {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.font.primary.normal};
  }

  li:last-of-type * {
    cursor: default;
  }
`;

export const StyledStickyMenu = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.md} {
    display: block;
  }
`;

export const StyledSvg = styled(Svg)`
  height: 43px;
  width: 72px;
  cursor: pointer;
`;

export const StyledTradingDay = styled.span`
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 900;
    `}
`;
