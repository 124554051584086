import { Col } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

const getItemStyledByType = ({ type }) => {
  if (type === 'title') {
    return css`
      font-weight: 900;
      min-height: 30px;
      margin-bottom: ${({ theme }) => theme.utils.spacing(2)};
      font-size: ${({ theme }) => theme.font.size.paragraph3};
      ${({ theme }) => theme.breakpoints.md} {
        font-size: ${({ theme }) => theme.font.size.paragraph1};
      }
      ${({ theme }) => theme.breakpoints.lg} {
        min-height: 45px;
        font-size: ${({ theme }) => theme.font.size.paragraph2};
      }
    `;
  }
  return css`
    font-size: ${({ theme }) => theme.font.size.caption};
    ${({ theme }) => theme.breakpoints.md} {
      font-size: ${({ theme }) => theme.font.size.paragraph2};
    }
    ${({ theme }) => theme.breakpoints.lg} {
      font-size: ${({ theme }) => theme.font.size.paragraph3};
    }
  `;
};

export const StyledContainer = styled.div`
  overflow-y: hidden;
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.colors.font.primary.normal};
  background-color: ${({ theme }) => theme.colors.bg.footer.dark};
  ol {
    margin: 0;
    padding: 0;
  }
`;

export const StyledListContainer = styled.div`
  ${({ theme, displayNewsletter }) =>
    displayNewsletter
      ? css`
          padding: ${theme.utils.spacing(5, 0)};
        `
      : css`
          padding: ${theme.utils.spacing(13, 0)};
        `}
`;

export const StyledList = styled.ol`
  list-style: none;
  :nth-child(1),
  :nth-child(2) {
    margin-bottom: ${({ theme }) => theme.utils.spacing(10)};
  }
  ${({ theme }) => theme.breakpoints.lg} {
    margin: 0;
  }
`;

export const StyledListItem = styled.li`
  width: 85%;
  margin-bottom: ${({ theme }) => theme.utils.spacing(5)};
  ${(props) => getItemStyledByType(props)};
`;

export const StyledSocialMediaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

export const StyledImageSocialMediaContainer = styled.div`
  height: 21px;
  width: 21px;
  border-radius: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  z-index: 2;
  &:hover {
    background: ${({ theme }) => theme.colors.hovers.socialNetworks};
  }
  ${({ theme }) => theme.breakpoints.sm} {
    height: 34px;
    width: 34px;
  }
`;

export const StyledSocialMedia = styled.img`
  height: 12px;
  object-fit: cover;
  margin-right: 8px;
  :last-of-type {
    margin-right: 0px;
  }
  ${({ theme }) => theme.breakpoints.sm} {
    height: 20px;
  }
`;

export const StyledBrandsContainer = styled.div`
  display: flex;
  position: static;
  justify-content: flex-start;
  padding-top: ${({ theme }) => theme.utils.spacing(4)};
  padding-bottom: ${({ theme }) => theme.utils.spacing(5)};
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.separator.secondary.light};
  ${({ theme }) => theme.breakpoints.lg} {
    justify-content: start;
  }
`;

export const StyledGrid = styled.div`
  display: grid;
  width: 50%;
  grid-gap: 11px 9px;
  grid-auto-flow: row;
  ${({ isInternal }) =>
    !isInternal &&
    css`
      padding-left: 15px !important;
    `}
  ${({ theme }) => theme.breakpoints.xs} {
    grid-template-columns: repeat(auto-fill, minmax(51px, 62px));
    grid-template-rows: repeat(auto-fill, minmax(26px, 37px));
    ${({ isInternal }) =>
      isInternal &&
      css`
        padding-left: 30px !important;
      `}
  }
  ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: repeat(auto-fill, minmax(87px, 97px));
    grid-template-rows: repeat(auto-fill, minmax(44px, 54px));
    ${({ isInternal }) =>
      isInternal &&
      css`
        padding-left: 0px !important;
      `}
  }
  ${({ theme }) => theme.breakpoints.lg} {
    grid-template-columns: 18% 18% 18% 18% 18%;
  }
`;
export const StyledExternalBrands = styled.div`
  display: flex;
  width: 100%;
  > div {
    padding: ${({ theme }) => theme.utils.spacing(0, 2)};
  }
  ${({ theme }) => theme.breakpoints.md} {
    justify-content: start;
  }
`;

export const StyledBrand = styled.div`
  height: 44px;
  width: 87px;
  border-radius: 8px;
  justify-items: center;
  align-items: center;
  z-index: 2;
  background: ${({ theme }) => `${theme.colors.bg.certifications}`};
  &:hover {
    background: ${({ theme }) => theme.colors.hovers.brands};
  }
  & img {
    width: 87px;
    height: 39px;
    object-fit: contain;
  }

  & a {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  ${({ isInternal }) =>
    isInternal &&
    css`
      :first-of-type {
        position: absolute;
        background: transparent;
        width: 230px;
        height: 30px;
        left: 9px;
        bottom: 288px;
        justify-items: start;
        z-index: 1;
        & img {
          width: auto;
          height: 300px;
        }
        ${({ theme }) => theme.breakpoints.xs} {
          position: absolute;
          left: -3px;
          bottom: 323px;
        }

        ${({ theme }) => theme.breakpoints.sm} {
          left: -3px;
          bottom: 288px;
        }
      }
    `}
  
  ${({ theme }) => theme.breakpoints.xs} {
    width: 51px;
    height: 26px;
    &:first-of-type {max-width: 51px;}
    & img {
      width: 51px;
      height: 26px;
    }
  }

  ${({ theme }) => theme.breakpoints.sm} {
    height: 44px;
    width: 87px;
    &:first-of-type {max-width: 87px;}
    & a{
      height: 44px;
    }
    & img{
      height: 44px;
      width: 87px;
    }

    ${({ isInternal }) =>
      isInternal &&
      css`
        :first-of-type {
          display: absolute;
          bottom: 186px;
        }
      `}
  }

  ${({ theme }) => theme.breakpoints.lg} {
    ${({ isInternal }) =>
      isInternal &&
      css`
        max-width: 80px;
        & img { 
          max-width: 70px; 
          margin: 0 auto;
        }
    }`}

${({ theme }) => theme.breakpoints.lg} {
    ${({ isInternal }) =>
      !isInternal &&
      css`
        max-width: 80px !important;
        & img { 
          max-width: 80px;
          margin: 0 auto;
        }
    }`}

  padding: 5px !important;
`;

export const StyledSpacer = styled.div`
  padding: 0px !important;
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.colors.bg.footerSpacer};
`;

export const StyledCopyright = styled.div`
  width: 100%;
  text-align: center;
  padding: ${({ theme }) => theme.utils.spacing(2, 0)};
  ${({ theme }) => theme.breakpoints.md} {
    > span {
      font-size: ${({ theme }) => theme.font.size.paragraph3};
    }
  }
`;
