import PropTypes from 'prop-types';
import { Modal, Scrollbar } from '@bvcco/bvc-digital-market-component-library';

import {
  StyledTitle,
  StyledContent,
  StyledContentText
} from './TermsAndConditionsModal.styled';

const TermsAndConditionsModal = ({ title, content, isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    color='quinary'
    colorType='dark'
    colorOverlay='overlayDark'
    colorOverlayType='normal'
  >
    <StyledTitle
      type='h5'
      color='tertiary'
      textAlign='center'
      fontWeight={700}
      textTransform='capitalize'
    >
      {title}
    </StyledTitle>
    <StyledContent>
      <Scrollbar>
        <StyledContentText
          color='tertiary'
          type='paragraph2'
          textAlign='justify'
          lineHeight='small'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Scrollbar>
    </StyledContent>
  </Modal>
);

TermsAndConditionsModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TermsAndConditionsModal;
