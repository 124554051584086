import styled, { css } from 'styled-components';

export const StyledFontSwitcher = styled.div`
  font-family: ${({ theme }) => theme.font.family.primary};
`;

export const StyledFontButton = styled.button`
  color: ${({ theme }) => theme.colors.font.primary.normal};
  background: ${({ theme }) => theme.colors.bg.transparent.normal};
  border: none;
  font-size: ${({ theme, fontSize }) => theme.font.size[fontSize]};
  font-family: ${({ theme }) => theme.font.family.secondary};
  position: relative;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      color: ${theme.colors.font.secondary.normal};

      &::after {
        content: '';
        background-color: ${theme.colors.font.secondary.normal};
        bottom: -6px;
        right: 5px;
        height: 5px;
        position: absolute;
        width: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
    `};
`;
