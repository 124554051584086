import styled, { css } from 'styled-components';

import {
  Svg,
  Button,
  Select
} from '@bvcco/bvc-digital-market-component-library';

export const StyledAccessibilityBar = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.accessibilityBar.dark};
  width: 100%;
  ${({ isMobile, showHeaderLogin }) => {
    if (showHeaderLogin) {
      if (isMobile) {
        return css`
          height: '48px !important';
        `;
      }
    }
  }};
`;

export const StyledLeftItemsContainer = styled.div`
  align-items: start;
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  & > div {
    display: none;
  }

  & label {
    font-size: ${({ theme }) => theme.font.size.paragraph3};
    margin: ${({ theme }) => theme.utils.spacing(1, 0)};
    padding-right: ${({ theme }) => theme.utils.spacing(13)};
  }

  ${({ theme }) => theme.breakpoints.sm} {
    & label {
      font-size: ${({ theme }) => theme.font.size.paragraph2};
    }
    & > div {
      display: block;
      padding: ${({ theme }) => theme.utils.spacing(0, 4)};
    }
  }
`;

// width: 68px; es el que determina el ancho del servcio al cliente.
export const StyledRigthItemsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  margin-right: ${({ isMobile }) =>
    isMobile ? '0px !important' : '20px !important'};
  /* With header login remove space in vertical bar margin-right: ${({
    theme
  }) => theme.utils.spacing(1)}; */
  & button {
    height: 100%;
    min-width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & button span {
    display: flex;
  }
  & button:last-of-type {
    margin-right: 0;
    padding-right: 0;
    margin-left: 0;
    padding-left: 0;
    min-width: 50px;
  }

  ${({ theme }) => theme.breakpoints.sm} {
    justify-content: flex-end;
    margin-right: 0;

    & button {
      margin: ${({ theme }) => theme.utils.spacing(0, 2)};
    }
  }
`;

export const StyledSvg = styled(Svg)`
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const StyledSpacer = styled.div`
  background-color: ${({ theme }) => theme.colors.separator.tertiary.dark};
  height: 100%;
  width: 1px;
  visibility: ${({ activeHoverDeceval }) =>
    activeHoverDeceval ? 'hidden' : 'visible'};

  ${({ theme }) => theme.breakpoints.sm} {
    margin: ${({ theme }) => theme.utils.spacing(0, 1)};

    &:first-of-type {
      display: none;
    }
  }
  margin: 0px !important;
`;

export const StyledSpacerServiceCliente = styled(StyledSpacer)`
  visibility: ${({ activeHoverServiceCliente }) =>
    activeHoverServiceCliente ? 'hidden' : 'visible'};
`;

export const StyledSelectIcon = styled.img`
  margin-top: ${({ theme }) => theme.utils.spacing(1)};
  height: 14px;
  width: 18px;
`;

export const StyledButtonIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export const StyledSelect = styled(Select)`
  width: 75px;
  min-width: 57px;
  & > div {
    font-size: ${({ theme }) => theme.font.size.buttonSmall};
  }
`;

export const StyledButtonServiceClient = styled(Button)`
  width: ${({ isMobile }) => (isMobile ? '0px' : '152px')};
  ${({ isMobile, isAuthenticated }) => {
    if (!isMobile) {
      return css`
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.colors.bg.buttonLogin.normal};
        height: 32px !important;
        padding-right: 3px !important;
        margin-right: 5px !important;
      `;
    } else if (isMobile) {
      if (isAuthenticated) {
        return css`
          min-width: 39px !important;
        `;
      } else {
        return css`
          min-width: 25px !important;
        `;
      }
    }
  }};
`;

export const StyledButtonLogin = styled(Button)`
  width: 91px;
  & > span {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &:last-of-type {
    padding-left: 12px !important;
    padding-right: 17px !important;
    min-width: 91px !important;
  }
`;

export const StyledLoginDot = styled.span`
  background-color: ${({ theme }) => theme.colors.bg.danger.normal};
  border-radius: ${({ theme }) => theme.borderRadius.total};
  display: block;
  margin-left: ${({ theme }) => theme.utils.spacing(2)};
  height: 4px;
  width: 4px;
  ${({ theme }) => theme.breakpoints.sm} {
    margin-left: ${({ theme }) => theme.utils.spacing(3)};
  }
`;

const isFluidContainer = ({ theme, fluid, limit }) =>
  fluid &&
  css`
    width: ${theme.containerWidth.fluid.width};
    max-width: ${limit ? theme.containerWidth.fluid.maxWidth : 'none'};
  `;

export const ContainerHeader = styled.div`
  height: 56px;
  margin: 0 auto;
  width: ${({ theme }) => theme.containerWidth.xs.width};
  max-width: ${({ theme }) => theme.containerWidth.xs.maxWidth};
  ${isFluidContainer}

  @media screen and (max-width: 769px) {
    padding: 0;
    height: auto;
  }

  ${({ isN2 }) =>
    isN2
      ? css`
          ${({ theme }) => theme.breakpoints.md} {
            width: ${({ theme }) => theme.containerWidth.sm.width};
            max-width: ${({ theme }) => theme.containerWidth.sm.maxWidth};
            ${isFluidContainer}
          }
        `
      : css`
          ${({ theme }) => theme.breakpoints.md} {
            width: ${({ theme }) => theme.containerWidth.md.width};
            max-width: ${({ theme }) => theme.containerWidth.md.maxWidth};
            ${isFluidContainer}
          }
        `}

  ${({ isN2 }) =>
    isN2
      ? css`
          ${({ theme }) => theme.breakpoints.lg} {
            width: ${({ theme }) => theme.containerWidth.sm.width};
            max-width: ${({ theme }) => theme.containerWidth.sm.maxWidth};
            ${isFluidContainer}
          }
        `
      : css`
          ${({ theme }) => theme.breakpoints.lg} {
            width: ${({ theme }) => theme.containerWidth.lg.width};
            max-width: ${({ theme }) => theme.containerWidth.lg.maxWidth};
            ${isFluidContainer}
          }
        `}
`;
