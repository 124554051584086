import NextLink from 'next/link';
import PropTypes from 'prop-types';
import useRouter from '@hooks/useRouter';
import StyledLink from './Link.styled.js';
import { childrenProps } from '@config/common-propTypes';
import { isAValidExternalUrl, isValidEmail } from '@utils/strings';

const Link = ({ asPath, to, target, children, ...rest }) => {
  const router = useRouter();
  const handleClick = (e) => {
    e.preventDefault();
    router.push(to, undefined, undefined, target);
  };
  if (to) {
    return isAValidExternalUrl(to) || isValidEmail(to) ? (
      <StyledLink
        href={to}
        onClick={handleClick}
        target={target}
        rel='noopener noreferrer external nofollow'
        {...rest}
      >
        {children}
      </StyledLink>
    ) : (
      <NextLink href={to} onClick={handleClick} as={asPath}>
        <StyledLink {...rest}>{children}</StyledLink>
      </NextLink>
    );
  } else {
    return <></>;
  }
};

Link.propTypes = {
  asPath: PropTypes.string,
  to: PropTypes.string,
  children: childrenProps.isRequired,
  target: PropTypes.string
};
Link.defaultProps = {
  target: '_blank',
  asPath: undefined,
  to: '/'
};

export default Link;
