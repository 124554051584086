import { useState, useCallback } from 'react';

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
      }
      setValue(!value);
    },
    [value]
  );

  return [value, toggle];
};

export default useToggle;
