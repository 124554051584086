import {
  ContainerAvatar,
  ContainerDescription,
  ContainerOptions,
  ContainerOptionsLogout,
  Text,
  SeparateVertical,
  StyledSvg,
  StyledSvgBack,
  Description
} from './ModalPerfil.styled';
import IconBack from '@assets/icons/back.svg';
import ImageAvatar from '@assets/images/avatar.svg';
import PropTypes from 'prop-types';
import { logout } from '@utils/session';
import { useDispatch } from 'react-redux';
import changeAuthActions from '@redux/reducers/auth';
import { upperCaseFirstLetter } from '@utils/strings';
import personalAccounts from '../constant';

const ModalPerfil = ({ userLogged, modalProfile }) => {
  const { userType, name, lastName, token } = userLogged || {};
  const { myPersonAccount, companyAccount, goToAccount, logOut } = modalProfile;
  const dispatch = useDispatch();
  const setUser = () => {
    dispatch(changeAuthActions.changeAuth(null));
  };
  return (
    <>
      <ContainerAvatar>
        <StyledSvg svg={ImageAvatar} />
      </ContainerAvatar>
      <Text>
        {upperCaseFirstLetter(name)} {upperCaseFirstLetter(lastName)}
      </Text>
      <ContainerDescription>
        <Description>
          {personalAccounts.includes(userType)
            ? myPersonAccount
            : companyAccount}
        </Description>
      </ContainerDescription>
      <SeparateVertical />
      <ContainerOptions
        onClick={() => {
          window.location.href = process.env.NEXT_PUBLIC_BVC_HOME;
        }}
        between
      >
        <Text>{goToAccount}</Text> <StyledSvgBack svg={IconBack} />
      </ContainerOptions>
      <SeparateVertical />
      <ContainerOptionsLogout onClick={() => logout(token, setUser)}>
        <Text>{logOut}</Text>
      </ContainerOptionsLogout>
    </>
  );
};
ModalPerfil.propTypes = {
  userLogged: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    token: PropTypes.string
  }).isRequired,
  modalProfile: PropTypes.shape({
    myPersonAccount: PropTypes.string,
    companyAccount: PropTypes.string,
    goToAccount: PropTypes.string,
    logOut: PropTypes.string
  }).isRequired
};
export default ModalPerfil;
