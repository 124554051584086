import { Formik } from 'formik';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Typography } from '@bvcco/bvc-digital-market-component-library';

import {
  StyledRow,
  StyledCol,
  StyledText,
  StyledCheckBox,
  StyledSendButton,
  StyledInputContainer,
  StyledTermsAndConditions
} from './Newsletter.styled';
import newsletterSchema from './Newsletter.schema';
import TermsAndConditionsModal from './TermsAndConditionsModal/TermsAndConditionsModal';

import SendICon from '@assets/icons/send.svg';

const initialValues = { email: '', termsAndConditions: false };

const Newsletter = ({
  inputLabel,
  newsletterText,
  inputPlaceholder,
  normalCheckboxLabel,
  termsAndConditionsTitle,
  underlinedCheckboxLabel,
  termsAndConditionsContent
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModalTermsAndConditions = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onSubmitNewsletter = () => {};

  return (
    <>
      <StyledRow>
        <StyledCol xs={12} md={6}>
          <StyledText color='primary' type='h5' as='p'>
            {newsletterText}
          </StyledText>
        </StyledCol>
        <StyledCol xs={12} md={6}>
          <Formik
            id='newsletter-form'
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              onSubmitNewsletter(values);
              setSubmitting(false);
            }}
            validationSchema={newsletterSchema}
          >
            {({ handleBlur, handleChange, handleSubmit, errors, values }) => (
              <form onSubmit={handleSubmit}>
                <StyledInputContainer>
                  <Input
                    fullWidth
                    id='email'
                    name='email'
                    label={inputLabel}
                    labelFontWeight='bold'
                    onBlur={handleBlur}
                    fontSize='paragraph2'
                    labelFontSize='caption'
                    helps={[{ text: t(errors.email), type: 'danger' }]}
                    onChange={handleChange}
                    icon={{
                      icon: (
                        <StyledSendButton type='submit'>
                          <SendICon />
                        </StyledSendButton>
                      )
                    }}
                    placeholder={inputPlaceholder}
                    value={values.email}
                  />
                </StyledInputContainer>
                <StyledTermsAndConditions>
                  <StyledCheckBox
                    id='termsAndConditions'
                    name='termsAndConditions'
                    checked={values.termsAndConditions}
                    onChange={handleChange}
                    color='primary'
                    label={
                      <>
                        <Typography
                          as='span'
                          color='primary'
                          type='caption'
                          className='termsAndConditions--normal'
                        >
                          {normalCheckboxLabel}
                        </Typography>
                        <Typography
                          as='span'
                          color='primary'
                          onClick={toggleModalTermsAndConditions}
                          type='caption'
                          textDecoration='underline'
                          className='termsAndConditions--underlined'
                        >
                          {underlinedCheckboxLabel}
                        </Typography>
                      </>
                    }
                    error={errors.termsAndConditions}
                  />
                </StyledTermsAndConditions>
              </form>
            )}
          </Formik>
        </StyledCol>
      </StyledRow>
      <TermsAndConditionsModal
        isOpen={isModalOpen}
        title={termsAndConditionsTitle}
        content={termsAndConditionsContent}
        onClose={toggleModalTermsAndConditions}
      />
    </>
  );
};

Newsletter.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  normalCheckboxLabel: PropTypes.string.isRequired,
  underlinedCheckboxLabel: PropTypes.string.isRequired,
  newsletterText: PropTypes.string.isRequired,
  termsAndConditionsContent: PropTypes.string.isRequired,
  termsAndConditionsTitle: PropTypes.string.isRequired
};

export default Newsletter;
