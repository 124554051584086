import PropTypes from 'prop-types';
import { noveltyTypes, breakpoints } from '@config/constants';

const { SQUARE, RECTANGLE } = noveltyTypes;

export const propTypes = {
  index: PropTypes.number,
  type: PropTypes.oneOf([SQUARE, RECTANGLE]),
  breakpoint: PropTypes.oneOf([
    breakpoints.XS,
    breakpoints.SM,
    breakpoints.MD,
    breakpoints.LG,
    ''
  ]),
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mainImage: PropTypes.shape({
    url: PropTypes.string,
    fileName: PropTypes.string
  }),
  categories: PropTypes.arrayOf(PropTypes.string),
  author: PropTypes.string,
  dateAndTime: PropTypes.string
};

export const defaultProps = {
  index: 0,
  type: SQUARE,
  breakpoint: undefined,
  id: undefined,
  title: undefined,
  author: undefined,
  subtitle: undefined,
  image: {
    url: '',
    fileName: ''
  },
  categories: undefined,
  dateAndTime: undefined
};
