import styled from 'styled-components';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

export const StyledTitle = styled(Typography)`
  margin: ${({ theme }) => theme.utils.spacing(4, 0)};

  ${({ theme }) => theme.breakpoints.md} {
    margin-top: 0;
  }
`;

export const StyledContentText = styled(Typography)`
  margin: 0 auto;
  width: 90%;

  ${({ theme }) => theme.breakpoints.md} {
    width: 80%;
  }
`;

export const StyledContent = styled.div`
  & .scrollbar-container {
    max-height: 60vh;
  }
  overflow-y: auto;
`;
