import styled, { css } from 'styled-components';
import { Button } from '@bvcco/bvc-digital-market-component-library';

export const SpanButton = styled.span``;
export const CustomBtn = styled(Button)`
  &:hover ${SpanButton} {
    color: ${({ theme }) => theme.colors.tooltip.customBtnColor};
  }
`;
const ContainerStyles = css`
  width: 100%;
  height: 100%;
`;

const SharedStyles = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ isMobile }) => {
    return isMobile ? '48px !important' : 'auto';
  }};
  text-align: center;
  height: auto;
`;

export const ContainerItems = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  background: ${({ theme, isMobile }) =>
    !isMobile ? theme.colors.tooltip.containerColor : ''};
`;

export const ServiceContainer = styled.div`
  width: 100%;
  ${SharedStyles};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.tooltip.labelColor};
  font-size: 11.4px;
  font-weight: bold;
`;
export const LoginContainer = styled.div`
  ${ContainerStyles}
  ${SharedStyles};
  background-color: ${({ theme }) => theme.colors.tooltip.loginBtnBackColor};
  color: ${({ theme }) => theme.colors.tooltip.loginBtnColor};
  font-size: 12px;
`;

export const RegisterContainer = styled.div`
  ${ContainerStyles}
  ${SharedStyles};
  background-color: ${({ theme }) => theme.colors.tooltip.loginBtnColor};
  color: ${({ theme }) => theme.colors.tooltip.loginBtnBackColor};

  font-size: 12px;
`;

export const DesktopContainer = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.tooltip.containerColor};
  height: 100%;
  width: 250px;
`;
export const LabelContainer = styled.div`
  width: 100px;
`;
export const DesktopBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const TooltipContainer = styled.div`
  width: ${({ isMobile }) => (isMobile ? '346px' : '438px')};
  height: ${({ isMobile }) => (isMobile ? '279px' : '270px')};
  background-color: ${({ theme }) =>
    theme.colors.tooltip.tooltipContainerColor};
  border-radius: 8px;
  position: absolute;
  top: 75px;
  ${({ isMobile }) => {
    if (typeof window !== 'undefined') {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      return (
        isMobile &&
        width < 456 &&
        css`
          left: 8%;
        `
      );
    }
  }};

  @media screen and (max-width: 1025px) {
    right: 25px;
  }
  @media screen and (min-width: 1025px) {
    left: calc(100% - ${({ isN2 }) => (isN2 ? '44%' : '49%')});
  }
`;

const ShareLeftContainer = css`
  display: flex;
  justify-content: flex-start;
`;
const ShareRightContainer = css`
  display: flex;
  justify-content: flex-end;
`;

export const CloseTooltipContainer = styled.div`
  ${ShareRightContainer};
  margin-top: 15px;
  margin-right: 12.02px;
  cursor: pointer;
`;

export const CloseTooltip = styled.span`
  width: 13.98px;
  height: 13.98px;
  color: ${({ theme }) => theme.colors.tooltip.closeBtnColor};
`;

export const IconContainer = styled.div`
  ${ShareLeftContainer};
  margin-left: 28px;
`;

export const Icon = styled.img`
  width: 49px;
  height: 49px;
`;

export const TitleContainer = styled.div`
  ${ShareLeftContainer};
  font-size: ${({ isMobile }) => (isMobile ? '24px' : '26px')};
  color: ${({ theme }) => theme.colors.tooltip.customBtnColor};
  font-weight: bold;
  margin-left: 28px;
  margin-top: 12px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.tooltip.customBtnColor};
  text-align: justify;
  margin-left: 28px;
  margin-right: 29px;
  margin-top: 15px;
  font-size: 14px;
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 15px solid
    ${({ theme }) => theme.colors.tooltip.tooltipContainerColor};
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? '-11px' : '-12px')};
  right: 50%;
    @media (min-width: 350px) and (max-width: 500px) {
    right: 27%;
  }
  @media (min-width: 501px) and (max-width: 565px) {
    right: 43%;
`;

export const ArrowContainer = styled.div`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
`;
