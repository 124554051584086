import { useEffect, useCallback, useState } from 'react';
import App from '@config/app';
import moment from 'moment';
import { getCookies, setCookie } from '@utils/cookies';

const useFetchNtp = () => {
  const getCookie = () => {
    const context = { req: { headers: { cookie: 'time' } } };
    const { time } = getCookies(context);
    return time;
  };

  const cookie = getCookie();

  const [hourGlobal, setHourGlobal] = useState(cookie);

  const fetchData = useCallback(async () => {
    if (!cookie) {
      const { api } = App.getInstance();
      const hora1 = moment();
      const historical = await api.ticker.getTime();

      const hora2 = moment();
      if (!historical.date) {
        setCookie('time', null);
        setHourGlobal(null);
        return;
      }

      const legalHour = moment(historical.date).unix();
      const diff1 = legalHour - hora1.unix();
      const diff2 = legalHour - hora2.unix();
      const diffTotal = ((diff1 + diff2) / 2) * 1000;

      setCookie('time', diffTotal);
      setHourGlobal(diffTotal);
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return hourGlobal;
};

export default useFetchNtp;
