/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ToolTipRender from './TooltipRender';
import redirectUrls from './config';

import {
  Container,
  ContainerDesktop,
  ServiceContainer,
  LoginContainer,
  BtnContainer,
  ContainerItems,
  Label,
  DesktopContainer,
  LabelContainer,
  DesktopBtnContainer
  /* Disabled by client
  RegisterContainer,
  TooltipContainer,
  CloseTooltipContainer,
  CloseTooltip,
  IconContainer,
  Icon,
  TitleContainer,
  DescriptionContainer,
  ArrowUp,
  ArrowContainer,
  CustomBtn,
  SpanButton
  */
} from './Tooltip.styled';
import useRouter from '@hooks/useRouter';
import { Button } from '@bvcco/bvc-digital-market-component-library';

const ContentTooltip = ({
  title,
  description,
  url,
  isMobile,
  leftBtnTitle,
  headerTitle,
  isN2
  /* Disabled by client
  onHandlerLogin,
  onHandlerRegister,
  onClose,
  rightBtnTitle,
  */
}) => {
  const router = useRouter();
  const { leftBtnUrl /* rightBtnUrl */ } = redirectUrls;
  let visibleTooltipLocal;
  if (typeof window !== 'undefined') {
    visibleTooltipLocal = window.localStorage.getItem('tooltip') || 'false';
  }
  const RenderDesktop = useCallback(() => (
    <>
      <DesktopContainer isMobile={isMobile}>
        <Container>
          <LabelContainer>
            <Label style={{ fontSize: 16 }}>{headerTitle}</Label>
          </LabelContainer>
        </Container>
        <DesktopBtnContainer>
          <BtnContainer>
            <Button
              textProps={{
                color: 'primary',
                colorType: 'normal',
                fontWeight: '900',
                fontSize: '8px'
              }}
              style={{
                fontSize: '14px',
                marginRight: '27px',
                height: '44px',
                borderRadius: '8px',
                width: '140px',
                marginBottom: '6px',
                marginTop: '6px'
              }}
              hoverColor='quinary'
              hoverColorType='light'
              onClick={() =>
                router.push({
                  pathname: leftBtnUrl,
                  isBVCDomain: true,
                  target: '_self'
                })
              }
            >
              {leftBtnTitle}
            </Button>
          </BtnContainer>
          {/* Disabled by client
          <BtnContainer>
            <CustomBtn
              color='footerText'
              textProps={{
                color: 'secondary',
                colorType: 'normal',
                fontWeight: '900',
                fontSize: '8px'
              }}
              hoverColor='quinary'
              hoverColorType='light'
              style={{
                fontSize: '14px',
                height: '44px',
                borderRadius: '8px',
                width: '140px'
              }}
              onClick={() =>
                router.push({
                  pathname: rightBtnUrl,
                  isBVCDomain: true
                })
              }
            >
              <SpanButton>{rightBtnTitle}</SpanButton>
            </CustomBtn>
          </BtnContainer>
          */}
        </DesktopBtnContainer>
      </DesktopContainer>
      {visibleTooltipLocal === 'false' && (
        <ToolTipRender
          title={title}
          description={description}
          url={url}
          isMobile={isMobile}
          isN2={isN2}
        />
      )}
    </>
  ));
  const RenderM = useCallback(() => (
    <Container isMobile={isMobile}>
      <ContainerItems isMobile={isMobile}>
        <ServiceContainer>
          <Label>{headerTitle}</Label>
        </ServiceContainer>
        <LoginContainer
          onClick={() =>
            router.push({
              pathname: leftBtnUrl,
              isBVCDomain: true,
              target: '_self'
            })
          }
        >
          {leftBtnTitle}
        </LoginContainer>
        {visibleTooltipLocal === 'false' && (
          <ToolTipRender
            title={title}
            description={description}
            url={url}
            isMobile={isMobile}
            isN2={isN2}
          />
        )}

        {/* Disabled by client
        <RegisterContainer
          onClick={() =>
            router.push({ pathname: rightBtnUrl, isBVCDomain: true })
          }
        >
          {rightBtnTitle}
        </RegisterContainer>
        */}
      </ContainerItems>
    </Container>
  ));
  if (isMobile) {
    return <RenderM />;
  }

  return <RenderDesktop />;
};

ContentTooltip.propTypes = {
  /**
   * @param: title String
   */
  title: PropTypes.string.isRequired,
  /**
   * @param description String
   */
  description: PropTypes.string.isRequired,
  /**
   * @param url String asset in web
   */
  url: PropTypes.string.isRequired,
  /**
   * @param isMobile Boolean
   */
  isMobile: PropTypes.bool.isRequired,
  /**
   * @param onHandlerLogin () => void
   */
  onHandlerLogin: PropTypes.func.isRequired,
  /**
   * @param onHandlerRegister () => void
   */
  onHandlerRegister: PropTypes.func.isRequired,
  /**
   * @param onClose () => voide
   */
  onClose: PropTypes.func.isRequired,
  /**
   * @param leftBtnTitle String
   * Title for the blue button
   */
  leftBtnTitle: PropTypes.string.isRequired,
  /**
   * @param rightBtnTitle String
   * Title for the white button
   */
  rightBtnTitle: PropTypes.string.isRequired,
  /**
   * @param headerTitle String
   * title besides of buttons
   */
  headerTitle: PropTypes.string.isRequired,
  /**
   * @param isN2 Bool
   * Know is N2
   */
  isN2: PropTypes.bool.isRequired
};

export default ContentTooltip;
