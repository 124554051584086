import NProgress from 'nprogress';

const start = () => {
  NProgress.start();
};

const done = () => {
  NProgress.done();
};

const loader = (showSpinner = false) => {
  NProgress.configure({ showSpinner });
  return { start, done };
};

export default loader;
