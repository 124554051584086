import styled from 'styled-components';
import { Svg } from '@bvcco/bvc-digital-market-component-library';
// FIXME: Get color by theme
export const ContainerAvatar = styled.div`
  padding: 15px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.bg.profileBg};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const StyledSvg = styled(Svg)`
  display: flex;
  width: 35px;
  height: 35px;
  margin-top: -2px;
  margin-left: -0.3px;
  align-items: center;
  svg {
    width: 35px;
  }
`;

export const StyledSvgBack = styled(Svg)`
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  margin-top: 4px;
  .svg {
    width: 18px;
  }
`;

export const ContainerDescription = styled.div`
  margin-bottom: 15px;
`;

export const Description = styled.span`
  font-family: 'Nunito';
  font-size: 12px;
  color: ${({ theme }) => theme.colors.font.success.normal};
`;
export const SeparateVertical = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.font.disabled.normal};
  opacity: 0.3;
  height: 2px;
  width: 100%;
`;

export const ContainerOptions = styled.div`
  width: 216px;
  padding: 10px 20px;
  display: flex;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-family: 'Nunito';
  justify-content: ${({ between }) =>
    between ? 'space-between' : 'flex-start'};
  align-items: center;
  :hover {
    background-color: ${({ theme }) => theme.colors.font.megaMenu.child};
  }
`;

export const ContainerOptionsLogout = styled.div`
  width: 216px;
  padding: 10px 20px;
  display: flex;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-family: 'Nunito';
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  justify-content: ${({ between }) =>
    between ? 'space-between' : 'flex-start'};
  align-items: center;
  :hover {
    background-color: ${({ theme }) => theme.colors.font.megaMenu.child};
  }
`;

/* disabled by client, show a new row in modal user profile to go to account
export const ContainerOptionsPerfil = styled.div`
  width: 216px;
  font-weight: normal !important;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  cursor: pointer;
  color: white;
  font-family: 'Nunito';
  font-size: 14px;
  :hover {
    background-color: ${({ theme }) => theme.colors.font.megaMenu.child};
  }
`;
*/

export const Text = styled.span`
  font-family: 'Nunito';
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.font.primary.normal};
`;
