import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import { useTranslation } from 'react-i18next';
import {
  MegaMenu,
  FancyLine,
  StickyMenu,
  Breadcrumb
} from '@bvcco/bvc-digital-market-component-library';

import {
  StyledSvg,
  StyledLayout,
  StyledContainer,
  StyledTradingDay,
  StyledStickyMenu,
  StyledBreadcrumbContainer
} from './Layout.styled';
import useDate from '@hooks/useDate';
import Seo from '@components/Seo/Seo';
import useLoader from '@hooks/useLoader';
import useRouter from '@hooks/useRouter';
import { useLight } from '@hooks/useConfig';
import Footer from '@components/Footer/Footer';
import useApiRequest from '@hooks/useApiRequest';
import { useMobile } from '@hooks/useBreakpoint';
import { childrenProps } from '@config/common-propTypes';
import { types, QUICK_LINKS_MAX_LENGTH } from '@config/constants';
import useLayoutSnippets from '@hooks/snippets/useLayoutSnippets';
import AccessibilityBar from '@components/AccessibilityBar/AccessibilityBar';
import BvcBlueIcon from '@assets/icons/bvc-orange.svg';
import BvcWhiteIcon from '@assets/icons/bvc.svg';
import { serializer } from './RoutingSerializer';
import useFetchNtp from '@hooks/local-market/level-1/useFetchNtp';
import { useSelector } from 'react-redux';

const Layout = ({
  children,
  layoutData,
  meta,
  breadcrumb,
  breadcrumbProps,
  snippets,
  showDivider,
  title,
  favicon
}) => {
  const isLight = useLight();
  const isMobile = useMobile();
  const router = useRouter();
  const hourGlobal = useFetchNtp();
  const { t } = useTranslation();
  const dateFormatter = useDate();
  const [{ data: tradingDay }] = useApiRequest({
    segment: types.DIV,
    endpoint: 'getTradingDay'
  });
  const internalSnippets = useLayoutSnippets(breadcrumb, snippets);
  useLoader({ defaultState: useLoader.states[1] });
  const { user, loading } = useSelector(({ auth }) => auth);

  const goTo = (path) => {
    const target = isMobile ? '_self' : '_blank';
    router.push(path, undefined, undefined, target);
  };

  const quickLinks = layoutData.quickLinks
    .slice(0, QUICK_LINKS_MAX_LENGTH)
    .map((link) => ({
      ...link,
      onClick: () => {
        router.push(link.url);
      }
    }));

  const getTradingDay = () => {
    const isTradingDay = tradingDay?.isTradingDay !== 'N';
    const todayDate = tradingDay?.date
      ? dateFormatter(tradingDay.date).format('YYYY/MM/DD')
      : dateFormatter().format('YYYY/MM/DD');
    const hourNtp = hourGlobal;

    return {
      isTradingDay,
      tradingDayCopy: isTradingDay ? t('tradingDay') : t('noTradingDay'),
      date: (
        <StyledTradingDay>
          <StyledTradingDay isBold>{t('today')}</StyledTradingDay> {todayDate}
        </StyledTradingDay>
      ),
      hourNtp,
      hour: dateFormatter().format('LT')
    };
  };

  const Logo = () => <StyledSvg svg={isLight ? BvcBlueIcon : BvcWhiteIcon} />;

  const currentURL = router.asPath;
  const isN2 = breadcrumb.length === 2;
  const urlsPaintMagicLine = serializer(layoutData.menu);

  const modifyBreadCrumm = () => {
    if (breadcrumb[2]) {
      const titleBreadCrumb = breadcrumb[2].title;
      const titleSplit = titleBreadCrumb.split(' ');
      if (titleSplit.length > 1) {
        breadcrumb[2].title = titleSplit[0];
      }
    }
  };
  modifyBreadCrumm();
  return (
    <StyledLayout>
      <Seo
        meta={meta}
        title={title}
        snippets={internalSnippets}
        slug={layoutData?.seo?.slug}
        favicon={favicon}
      />
      <Sticky enabled={!isMobile} innerZ={99} enableTransforms={false}>
        <Sticky enabled={isMobile} innerZ={99} enableTransforms={false}>
          <AccessibilityBar
            userLogged={user}
            loading={loading}
            modalLogin={layoutData.modalLogin}
            {...layoutData.accessibilityBar}
            displaySwitchers
            displayVirtualOffice={false}
            {...layoutData.tooltip}
            {...layoutData.headerLogin}
            isMobile={isMobile}
            modalProfile={layoutData.modalProfile}
            isN2={isN2}
          />
        </Sticky>
        <MegaMenu
          logo={Logo}
          className='layout-megaMenu'
          onClick={goTo}
          isMobile={isMobile}
          hamburgerMenuColor='megaMenuIcons'
          hamburgerMenuColorType='light'
          items={layoutData.menu}
          quickLinks={quickLinks}
          tradingDay={getTradingDay()}
          maxHeightMenuTitles='40px'
          maxHeightMenu='520px'
          displaySearch={false}
          currentURL={currentURL}
          urlsPaintMagicLine={urlsPaintMagicLine}
          isN2={isN2}
        />
      </Sticky>
      <StyledStickyMenu>
        <StickyMenu
          position='25%'
          items={quickLinks}
          borderColor='stickyMenu'
        />
      </StyledStickyMenu>
      <StyledContainer>
        {showDivider && (
          <FancyLine
            lineColor='primary'
            height='2px'
            width='100%'
            withoutMargin
            withSquare={false}
          />
        )}
        {breadcrumb.length > 0 && (
          <StyledBreadcrumbContainer isN2={isN2}>
            <Breadcrumb
              items={breadcrumb}
              id='bread-crumb'
              fontProps={{
                as: 'p',
                type: 'help',
                color: 'quaternary',
                colorType: 'normal',
                fontWeight: 400
              }}
              {...breadcrumbProps}
            />
          </StyledBreadcrumbContainer>
        )}
        {children}
      </StyledContainer>
      <Footer
        newsletter={layoutData.newsletter}
        footer={layoutData.footer}
        displayNewsletter={false}
      />
    </StyledLayout>
  );
};

Layout.propTypes = {
  children: childrenProps.isRequired,
  layoutData: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
      lastName: PropTypes.string
    }),
    seo: PropTypes.shape({
      slug: PropTypes.string
    }).isRequired,
    modalLogin: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired
        })
      ).isRequired
    }).isRequired,
    accessibilityBar: PropTypes.shape(AccessibilityBar.propTypes).isRequired,
    newsletter: PropTypes.shape(Footer.propTypes.newsletter).isRequired,
    quickLinks: PropTypes.shape(StickyMenu.propTypes).isRequired,
    footer: PropTypes.shape(Footer.propTypes.footer).isRequired,
    menu: PropTypes.shape({}).isRequired,
    tooltip: PropTypes.shape({}).isRequired,
    headerLogin: PropTypes.shape({}).isRequired,
    modalProfile: PropTypes.shape({}).isRequired
  }).isRequired,
  meta: Seo.propTypes.meta,
  breadcrumb: Breadcrumb.propTypes.items,
  breadcrumbProps: PropTypes.shape({}),
  showDivider: PropTypes.bool,
  title: Seo.propTypes.title,
  snippets: Seo.propTypes.snippets,
  favicon: PropTypes.string,
  tooltip: PropTypes.shape({})
};

Layout.defaultProps = {
  meta: Seo.defaultProps.meta,
  breadcrumb: [],
  breadcrumbProps: {},
  showDivider: false,
  title: Seo.defaultProps.title,
  snippets: Seo.defaultProps.snippets,
  favicon: {},
  tooltip: {}
};

export default Layout;
