import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  TooltipContainer,
  CloseTooltipContainer,
  CloseTooltip,
  IconContainer,
  Icon,
  TitleContainer,
  DescriptionContainer,
  ArrowUp,
  ArrowContainer
} from './Tooltip.styled';

const ToolTipRender = ({ title, description, url, isMobile, isN2 }) => {
  const [showTooltip, setShowTooltip] = useState(true);
  const onCloseHandler = () => {
    setShowTooltip(false);
    window.localStorage.setItem('tooltip', 'true');
  };
  const RenderTooltip = useCallback(() => {
    if (showTooltip) {
      return (
        <TooltipContainer isMobile={isMobile} isN2={isN2}>
          <ArrowContainer isMobile={isMobile}>
            <ArrowUp isMobile={isMobile} />
          </ArrowContainer>
          <CloseTooltipContainer>
            <CloseTooltip onClick={onCloseHandler}>X</CloseTooltip>
          </CloseTooltipContainer>
          <IconContainer>
            <Icon src={url} />
          </IconContainer>
          <TitleContainer isMobile={isMobile}>{title}</TitleContainer>
          <DescriptionContainer isMobile={isMobile}>
            {description}
          </DescriptionContainer>
        </TooltipContainer>
      );
    }
    return null;
  }, [description, isMobile, showTooltip, title, url]);

  return <RenderTooltip />;
};
ToolTipRender.propTypes = {
  /**
   * @param: title String
   */
  title: PropTypes.string.isRequired,
  /**
   * @param description String
   */
  description: PropTypes.string.isRequired,
  /**
   * @param url String asset in web
   */
  url: PropTypes.string.isRequired,
  /**
   * @param isMobile Boolean
   */
  isMobile: PropTypes.bool.isRequired
};
export default ToolTipRender;
