import styled from 'styled-components';

import { Svg } from '@bvcco/bvc-digital-market-component-library';

export const CustomStyledSelect = styled.div`
  width: 20;
`;
export const Container = styled.div`
  display: flex;
  font-family: 'Nunito';
  background-color: ${({ theme }) => theme.colors.userBar.containerColor};
  height: 100%;
  max-width: 256px;
  min-width: 150px;
  flex-direction: row;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .modalPerfil {
    cursor: auto;
    border-radius: 8px;
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 1000;
    background: ${({ theme }) => theme.colors.bg.datePickerN3.dark};
    top: 60px;
    left: 0;
    width: 256px;

    @media only screen and (max-width: 48em) {
      height: 233px;
      top: 62px;
      left: -41px;
    }

    @media only screen and (max-width: 48em) and (min-width: 38em) {
      top: 62px;
      left: 0;
    }

    @media only screen and (max-width: 38em) and (min-width: 30em) {
      top: 62px;
      left: -55px;
    }

    @media only screen and (max-width: 30em) and (min-width: 26em) {
      top: 62px;
      left: -85px;
    }

    @media only screen and (max-width: 26em) and (min-width: 24em) {
      top: 62px;
      left: -105px;
    }

    @media only screen and (max-width: 24em) {
      top: 62px;
      left: -110px;
    }
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.font.megaMenu.child};
  }
`;

export const ContainerIcon = styled.div`
  height: 100%;
  max-width: 85px;
  min-width: 35px;
  display: flex;
  align-items: center;
  .modalPerfil {
    width: 85px;
    border-radius: 8px;
    display: none;
    flex-direction: column;
    align-items: center;
    height: 272px;
    position: absolute;
    z-index: 1000;
    background: ${({ theme }) => theme.colors.font.primaryButton.normal};
    top: 65px;
    left: 0;
    width: 256px;

    @media only screen and (max-width: 48em) {
      top: 50px;
      left: -45px;
    }
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.font.megaMenu.child};
  }
`;
export const ContainerTitle = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  vertical-align: center;
  text-align: center;
  align-items: center;
`;

export const Title = styled.span`
  color: white;
  font-weight: ${({ bold }) => bold && 'bold'};
`;

export const ArrowIcon = styled(Svg)`
  & svg {
    width: 12px;
    height: 10px;
    margin: auto;
    color: ${({ theme }) => theme.colors.font.primary.normal};
    font-weight: bold;
    transition: all 0.3s ease;
    transform: ${({ $visibleperfil }) =>
      $visibleperfil ? 'rotate(-180deg)' : 'rotate(0deg)'};
  }
`;
