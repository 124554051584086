import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  Checkbox,
  Typography
} from '@bvcco/bvc-digital-market-component-library';

export const StyledRow = styled(Row)`
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.separator.secondary.light};
  padding-top: ${({ theme }) => theme.utils.spacing(10)};
  padding-bottom: ${({ theme }) => theme.utils.spacing(10)};
`;

export const StyledCol = styled(Col)`
  &:first-of-type {
    padding-bottom: ${({ theme }) => theme.utils.spacing(4)};
  }
  &:last-of-type {
    padding-top: ${({ theme }) => theme.utils.spacing(4)};
  }

  ${({ theme }) => theme.breakpoints.md} {
    &:first-of-type {
      padding-bottom: 0;
    }
    &:last-of-type {
      padding-top: 0;
    }
  }
`;

export const StyledText = styled(Typography)`
  width: 80%;
  font-size: ${({ theme }) => theme.font.size.h5};
  line-height: ${({ theme }) => theme.font.lineHeight.normal};
  ${({ theme }) => theme.breakpoints.md} {
    width: 70%;
  }

  ${({ theme }) => theme.breakpoints.xs} {
    font-size: ${({ theme }) => theme.font.size.subtitle1};
  }
`;

export const StyledInputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.utils.spacing(4)};
`;

export const StyledTermsAndConditions = styled.div`
  align-items: center;
  display: flex;
  .termsAndConditions--normal {
    margin-right: ${({ theme }) => theme.utils.spacing(1)};
  }
  & > div {
    margin-right: ${({ theme }) => theme.utils.spacing(1)};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSendButton = styled.button`
  background-color: ${({ theme }) => theme.colors.bg.transparent.normal};
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
    & svg {
      transform: scale(1.1);
    }
  }
`;

export const StyledCheckBox = styled(Checkbox)`
  z-index: ${({ theme }) => theme.zIndex.forward};
`;
