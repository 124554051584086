import styled from 'styled-components';

export default styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  &:hover {
    font-weight: bold;
  }
`;
