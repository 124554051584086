export const serializer = ( categories ) => {
    const newMatrix = [];
    let matrixIndex = 0;

    for (const item in categories) {
      const subCategory = categories[item].children;

      for (const subItem in subCategory) {
        const myContainer = subCategory[subItem];

        for (const element in myContainer) {
          if(myContainer[element].url) {
            newMatrix.push(
              {
                url: myContainer[element].url,
                index: matrixIndex
              } 
            );
          }
        }
      }
      matrixIndex++;
    }

    return newMatrix;
  }