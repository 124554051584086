import PropTypes from 'prop-types';
import useRouter from '@hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  Button,
  Switch,
  Container
} from '@bvcco/bvc-digital-market-component-library';

import {
  /* alignments, disabled by client */
  breakpoints,
  languages as languagesConstant,
  themes
} from '@config/constants';
import {
  /* disabled by client
  StyledSpacer,
  StyledSpacerServiceCliente,
  */
  StyledSvg,
  StyledSelect,
  StyledLoginDot,
  StyledButtonIcon,
  StyledSelectIcon,
  StyledButtonLogin,
  StyledAccessibilityBar,
  StyledLeftItemsContainer,
  StyledRigthItemsContainer,
  StyledButtonServiceClient,
  ContainerHeader
} from './AccessibilityBar.styled';
import useToggle from '@hooks/useToggle';
import useConfig from '@hooks/useConfig';
import Login from '@components/Login/Login';
import useBreakpoint from '@hooks/useBreakpoint';
import FontSwitcher from './FontSwitcher/FontSwitcher';
import VirtualOfficeIcon from '@assets/icons/virtual_office.svg';
import { splitWords, upperCaseFirstLetter } from '@utils/strings';
import { useState } from 'react';
import ContentTooltip from '@components/Tooltip/Tooltip';
import UserBar from '@components/UserBar/UserBar';

const AccessibilityBar = ({
  modalLogin,
  languages,
  themeModes,
  serviceClient,
  loginButtonText,
  letterSwitchText,
  displaySwitchers,
  displayVirtualOffice,
  isMobile,
  userLogged,
  modalProfile,
  isN2,
  ...props
}) => {
  const { t } = useTranslation();
  const [, /* activeHoverServiceCliente */ setActiveHoverServiceCliente] =
    useState(false);
  const [, /* activeHoverDeceval */ setActiveHoverDeceval] = useState(false);
  const currentBreakpoint = useBreakpoint();
  const { showHeaderLogin, loading } = props;
  const isAuthenticated = userLogged !== null || loading;
  let gridCol = {
    displaySwitchers: 12,
    noDisplaySwitchers: 12
  };
  if (showHeaderLogin) {
    gridCol = {
      displaySwitchers: isAuthenticated ? 5 : 3,
      noDisplaySwitchers: isAuthenticated ? 8 : 9 // revert this to original
    };
  }
  const [
    { language, theme, fontSize },
    { setFontSize, toggleLanguage, toggleTheme }
  ] = useConfig();
  const router = useRouter();

  const optionsSelect = languages.map(({ icon, label }, index) => ({
    icon: <StyledSelectIcon src={icon} alt={label} key={`${label}-${index}`} />,
    label,
    value: label.toLowerCase()
  }));
  const selectedLanguageValue = optionsSelect.find(
    ({ value }) => value === language
  );

  const redirectTo = (to, as) => {
    router.push(to, as, { shallow: true });
  };

  const getCurrentThemeLabel = () => {
    const mobileBreakpoints = [breakpoints.XS, breakpoints.SM];
    const cutPosition = languagesConstant.EN === language ? 0 : 1;
    if (theme === themes.LIGHT) {
      if (mobileBreakpoints.includes(currentBreakpoint)) {
        return upperCaseFirstLetter(splitWords(themeModes.light)[cutPosition]);
      }
      return themeModes.light;
    } else {
      if (mobileBreakpoints.includes(currentBreakpoint)) {
        return upperCaseFirstLetter(splitWords(themeModes.dark)[cutPosition]);
      }
      return themeModes.dark;
    }
  };

  const [isOn, setIsOn] = useToggle();

  const changeActiveHoverServiceClient = (state) => {
    setActiveHoverServiceCliente(state);
  };

  const changeActiveHoverDeceval = (state) => {
    setActiveHoverDeceval(state);
  };
  const onChangeToggleLanguage = ({ value }) => {
    value !== language && toggleLanguage();
  };
  const RenderHeader = () => {
    let content;
    if (showHeaderLogin) {
      if (isAuthenticated && !loading) {
        // TODO: CMS configuration
        content = <UserBar userInfo={userLogged} modalProfile={modalProfile} />;
      } else if (!loading && !isAuthenticated) {
        // FIXME: handlers function missing
        content = <ContentTooltip {...props} isMobile={isMobile} isN2={isN2} />;
      } else {
        return null;
      }
      return !loading ? (
        <Col
          xs={displaySwitchers ? 11 : isAuthenticated ? 6 : 8}
          sm={displaySwitchers ? 12 : isAuthenticated ? 2 : 3}
          style={{ height: isAuthenticated && '100%' }} // Disabled my client, revert this to original
        >
          {content}
        </Col>
      ) : (
        <></>
      );
    }
    return null;
  };
  return (
    <StyledAccessibilityBar
      isMobile={isMobile}
      showHeaderLogin={showHeaderLogin}
    >
      <ContainerHeader isMobile={isMobile} isN2={isN2}>
        {!loading && (
          <Row style={{ height: '100%' }}>
            {displaySwitchers && (
              <Col
                xs={3}
                sm={4}
                style={{
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <StyledLeftItemsContainer>
                  <Switch
                    checked={theme === themes.DARK}
                    id='accessibilityBar-switch-darkMode'
                    labelPlacement='start'
                    labelColor='primary'
                    labelFontSize='caption'
                    onChange={() => toggleTheme()}
                    type='filled'
                    bgColor={{
                      checked: { color: 'primary', colorType: 'normal' },
                      unchecked: { color: 'primary', colorType: 'normal' }
                    }}
                    circleColor={{
                      checked: {
                        color: 'background',
                        colorType: 'normal'
                      },
                      unchecked: {
                        color: 'background',
                        colorType: 'normal'
                      }
                    }}
                  >
                    {getCurrentThemeLabel()}
                  </Switch>
                  {/*
                  <FontSwitcher
                    selectedFontSize={fontSize}
                    onChangeFontSize={setFontSize}
                    letterSwitchText={letterSwitchText}
                  />
                  */}
                </StyledLeftItemsContainer>
              </Col>
            )}
            <Col
              xs={displaySwitchers ? 4 : gridCol.displaySwitchers}
              sm={displaySwitchers ? 4 : gridCol.noDisplaySwitchers}
            >
              <StyledRigthItemsContainer isMobile={isMobile}>
                <StyledButtonServiceClient
                  isMobile={isMobile}
                  isAuthenticated={isAuthenticated}
                  color='transparent'
                  rounded={false}
                  withoutMargin
                  hoverColor='buttonAccessibilityBar'
                  hoverColorType='light'
                  startIcon={
                    <StyledButtonIcon
                      src={serviceClient.icon}
                      alt={serviceClient.text}
                    />
                  }
                  textProps={{
                    color: 'primary',
                    colorType: 'normal',
                    fontFamily: 'primary',
                    fontSize: 'caption',
                    fontWeight: 800
                  }}
                  onClick={() =>
                    redirectTo('/customer-service', serviceClient.redirectTo)
                  }
                  fullWidth={false}
                  size='small'
                  onMouseEnter={() => {
                    changeActiveHoverServiceClient(true);
                  }}
                  onMouseLeave={() => {
                    changeActiveHoverServiceClient(false);
                  }}
                >
                  {currentBreakpoint !== breakpoints.XS && serviceClient.text}
                </StyledButtonServiceClient>

                {displayVirtualOffice && (
                  <Button
                    colorType='normal'
                    fullWidth={false}
                    rounded={false}
                    textProps={{
                      color: 'primaryButton',
                      colorType: 'dark',
                      fontFamily: 'primary',
                      fontSize: 'caption'
                    }}
                    size='small'
                  >
                    <StyledSvg svg={VirtualOfficeIcon} />
                    {currentBreakpoint !== breakpoints.XS &&
                      t('accesibilityBar.virtualOffice')}
                  </Button>
                )}
                <StyledSelect
                  activeBorderColor='transparent'
                  bgColor='transparent'
                  bgColorType='normal'
                  borderColor='transparent'
                  colorIndicators='primary'
                  menuListBgColor='accessibilityBar'
                  colorIndicatorsType='normal'
                  curvedBorders={false}
                  fontSize='caption'
                  placeholder=''
                  showIcons
                  isSearchable={false}
                  options={optionsSelect}
                  maxSelectedItems={2}
                  onChange={(e) => onChangeToggleLanguage(e)}
                  valueFontColor='primary'
                  optionFontColor='primary'
                  value={selectedLanguageValue}
                />
                {!showHeaderLogin && (
                  <StyledButtonLogin
                    rounded={false}
                    color='transparent'
                    fullWidth={false}
                    onClick={setIsOn}
                    hoverColor='buttonAccessibilityBar'
                    hoverColorType='light'
                    textProps={{
                      color: 'primary',
                      colorType: 'normal',
                      fontFamily: 'primary',
                      fontSize: 'caption',
                      fontWeight: 800
                    }}
                    size='small'
                    onMouseEnter={() => {
                      changeActiveHoverDeceval(true);
                    }}
                    onMouseLeave={() => {
                      changeActiveHoverDeceval(false);
                    }}
                  >
                    {loginButtonText}
                    <StyledLoginDot />
                  </StyledButtonLogin>
                )}
              </StyledRigthItemsContainer>
            </Col>
            <Col
              xs={5}
              sm={3}
              style={{ height: isAuthenticated && '56px', width: '200px' }}
            >
              <RenderHeader />
            </Col>
          </Row>
        )}
      </ContainerHeader>

      <Login isOpen={isOn} onClose={setIsOn} {...modalLogin} />
    </StyledAccessibilityBar>
  );
};

AccessibilityBar.propTypes = {
  props: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    showHeaderLogin: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    leftBtnTitle: PropTypes.string.isRequired,
    rightBtnTitle: PropTypes.string.isRequired,
    headerTitle: PropTypes.string.isRequired
  }).isRequired,
  showHeaderLogin: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  modalLogin: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  themeModes: PropTypes.shape({
    dark: PropTypes.string.isRequired,
    light: PropTypes.string.isRequired
  }).isRequired,
  serviceClient: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    redirectTo: PropTypes.string.isRequired
  }).isRequired,
  letterSwitchText: PropTypes.string.isRequired,
  loginButtonText: PropTypes.string.isRequired,
  displaySwitchers: PropTypes.bool.isRequired,
  displayVirtualOffice: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  userLogged: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    token: PropTypes.string
  }),
  loading: PropTypes.bool.isRequired,
  modalProfile: PropTypes.shape({
    myPersonAccount: PropTypes.string,
    companyAccount: PropTypes.string,
    goToAccount: PropTypes.string,
    logOut: PropTypes.string
  }).isRequired,
  isN2: PropTypes.bool.isRequired
};

AccessibilityBar.defaultProps = {
  userLogged: null
};

export default AccessibilityBar;
