import { useState, useEffect, useCallback } from 'react';

import App from '@config/app';

const useApiRequest = ({
  endpoint,
  segment,
  initialParams,
  autoLoad = true,
  includeEmptyData = false,
  initialState = undefined,
  initialLoadingState = false
}) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(initialLoadingState);

  const fetchData = useCallback(
    async (params, alternativeEndpoint, alternativeSegment) => {
      const { api } = App.getInstance();
      setIsLoading(true);
      try {
        const response = await api[alternativeSegment || segment][
          alternativeEndpoint || endpoint
        ](params);
        if (response || includeEmptyData) {
          setData(response);
        }
        setError(null);
        setIsLoading(false);
      } catch (err) {
        setError(err || true);
        setIsLoading(false);
      }
    },
    [segment, endpoint]
  );

  useEffect(() => {
    if (autoLoad) {
      fetchData(initialParams);
    }
  }, []);

  return [
    { data, error, isLoading },
    { setData, fetchData, setIsLoading }
  ];
};

export default useApiRequest;
