import PropTypes from 'prop-types';
import { Row } from 'react-styled-flexboxgrid';
import { Typography } from '@bvcco/bvc-digital-market-component-library';

import {
  StyledCol,
  StyledLink,
  StyledLabel,
  StyledModal,
  StyledButton
} from './Login.styled';

const buttonsModal = (buttons) =>
  buttons.map(({ id, url, text }) => (
    <StyledCol xs={12} sm={4} key={id}>
      <StyledLink
        href={url}
        target='_blank'
        rel='noopener noreferrer external nofollow'
      >
        <StyledButton
          color='buttonLogin'
          textProps={{
            color: 'login',
            colorType: 'light',
            fontWeight: 'lighter',
            fontFamily: 'secondary'
          }}
        >
          {text}
        </StyledButton>
      </StyledLink>
    </StyledCol>
  ));

const Login = ({ isOpen, onClose, title, subtitle, body, buttons }) => (
  <StyledModal
    isOpen={isOpen}
    onClose={onClose}
    color='login'
    colorType='dark'
    colorOverlay='overlayDark'
    colorOverlayType='normal'
  >
    <Typography
      textAlign='center'
      type='h3'
      color='loginTitle'
      fontWeight='bold'
    >
      {title}
    </Typography>
    <StyledLabel textAlign='center' color='loginText' fontWeight='lighter'>
      {body}
    </StyledLabel>
    <StyledLabel textAlign='center' color='loginTitle' fontWeight='bold'>
      {subtitle}
    </StyledLabel>
    <Row>{buttonsModal(buttons)}</Row>
  </StyledModal>
);

Login.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Login;
