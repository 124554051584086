import { useState, useEffect } from 'react';

import { getListSnippet } from '@utils/snippets';

const { NEXT_PUBLIC_URL } = process.env;

const useLayoutSnippets = (breadcrumb, previousSnippets) => {
  const [snippets, setSnippets] = useState([]);
  useEffect(() => {
    const snippetsArray = [];
    if (breadcrumb.length > 0) {
      const itemListSnippet = breadcrumb.map(
        ({ title: name, path }, index) => ({
          '@type': 'ListItem',
          position: index,
          name,
          item: `${NEXT_PUBLIC_URL}${path}`
        })
      );

      const breadcrumbSnippet = getListSnippet({
        items: itemListSnippet,
        type: 'BreadcrumbList'
      });
      snippetsArray.push(breadcrumbSnippet);
    }
    if (previousSnippets.length > 0) snippetsArray.push(...previousSnippets);
    snippetsArray.push({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: NEXT_PUBLIC_URL,
      logo: `${NEXT_PUBLIC_URL}/images/logo.png`
    });
    snippetsArray.push({
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: NEXT_PUBLIC_URL,
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '01 8000 111901',
          contactType: 'customer service'
        }
      ]
    });
    setSnippets(snippetsArray);
  }, [breadcrumb, previousSnippets]);

  return snippets;
};

export default useLayoutSnippets;
