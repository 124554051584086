import { useEffect } from 'react';

import Loader from '@config/lib/loader';

const states = ['UP', 'DOWN'];

const useLoader = ({ showSpinner, defaultState }) => {
  const loader = Loader(showSpinner);
  useEffect(() => {
    switch (defaultState) {
      case states[0]:
        loader.start();
        break;
      case states[1]:
        loader.done();
        break;
      default:
        break;
    }
  }, []);
  return [loader.start, loader.done];
};

useLoader.states = states;

export default useLoader;
