import { upperCaseFirstLetter } from '@utils/strings';

export const getListSnippet = ({ items = [], type, ...rest }) => ({
  '@context': 'https://schema.org',
  '@type': type,
  itemListElement: items,
  ...rest
});

export const getEventSnippet = ({ name, startDate, endDate, description }) => ({
  '@context': 'https://schema.org',
  '@type': 'Event',
  name,
  startDate,
  endDate,
  eventStatus: 'https://schema.org/EventScheduled',
  eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
  description,
  performer: {
    '@type': 'Organization',
    name: 'Bolsa de valores de Colombia'
  }
});

export const getCorporationSnippet = (symbols) =>
  symbols.map(({ mnemonic }) => ({
    '@type': 'Corporation',
    name: upperCaseFirstLetter(mnemonic.toLowerCase()),
    tickerSymbol: mnemonic.toUpperCase(),
    url: `${process.env.NEXT_PUBLIC_URL}/actions/${mnemonic.toLowerCase()}`
  }));

export const getUniqueCorporationSnippet = ({
  url,
  city,
  company,
  address,
  telephone
}) => ({
  '@type': 'Store',
  '@context': 'https://schema.org',
  url,
  telephone,
  address: {
    '@type': 'PostalAddress',
    streetAddress: address,
    addressLocality: city
  },
  name: upperCaseFirstLetter(company?.toLowerCase())
});
