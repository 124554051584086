import { useMemo, useState, useEffect } from 'react';

import { breakpoints, breakpointsValues } from '@config/constants';

const matches = (breakpoint) =>
  window.matchMedia(`(min-width: ${breakpoint}em)`).matches;

const getCurrentBreakpoint = () => {
  const { SM, MD, LG } = breakpointsValues;
  if (!process.browser) {
    return breakpoints.MD;
  }
  if (matches(SM) && !matches(MD)) {
    return breakpoints.SM;
  }
  if (matches(MD) && !matches(LG)) {
    return breakpoints.MD;
  }
  if (matches(LG)) {
    return breakpoints.LG;
  }
  return breakpoints.XS;
};

const useBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    getCurrentBreakpoint()
  );

  useEffect(() => {
    const updateSize = () => {
      const newBreakpoint = getCurrentBreakpoint();
      setCurrentBreakpoint(newBreakpoint);
    };
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return currentBreakpoint;
};

export default useBreakpoint;

export const useMobile = () => {
  const currentBreakpoint = useBreakpoint();

  const isMobileBreakpoint = useMemo(
    () =>
      currentBreakpoint === breakpoints.XS ||
      currentBreakpoint === breakpoints.SM,
    [currentBreakpoint]
  );

  return isMobileBreakpoint;
};
